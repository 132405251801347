<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">主要荣誉</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/大连市多规合一“一张蓝图”（第二阶段工作成果）.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连市多规合一“一张蓝图”（第二阶段工作成果）</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/大连2049城市愿景规划.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连2049城市愿景规划</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/大连市长海县广鹿岛镇总体规划（2011-2030）（2017年修订）.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连市长海县广鹿岛镇总体规划（2011-2030）（2017年修订）</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/庄河市仙人洞镇总体规划（2016-2030）.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">庄河市仙人洞镇总体规划（2016-2030）</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/中心城区轨道交通沿线土地开发策略及重要站点周边城市形态设计.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">中心城区轨道交通沿线土地开发策略及重要站点周边城市形态设计</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/大连钻石海湾地区城市设计—全国城市设计试点重点项目.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连钻石海湾地区城市设计—全国城市设计试点重点项目</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/大连市域0.6米卫星形象采购及DOM制作.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连市域0.6米卫星形象采购及DOM制作</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/大连东关街历史街区周边城市设计.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连东关街历史街区周边城市设计</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/岔山村发展建设规划.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">岔山村发展建设规划</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/大连市历史文化名城保护规划.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连市历史文化名城保护规划</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/大连市城镇体系规划（2018-2035年）.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连市城镇体系规划（2018-2035年）</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/城乡规划信息化空间管控技术体系及应用系统建设.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">城乡规划信息化空间管控技术体系及应用系统建设</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 100%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/hj/辽阳县前杜特色小镇建设规划.jpg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">辽阳县前杜特色小镇建设规划</div> 
        </div>
        <table cellspacing="0" style="width:100%;border:none;" class="ke-zeroborder">
          <tbody>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">序号</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">项目名称</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">获奖情况</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">类别</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="142" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">年份</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">1</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">大连市多规合一“一张蓝图”（第二阶段工作成果）</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">二等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">大连2049城市愿景规划</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">一等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="142" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">3</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">大连市长海县广鹿岛镇总体规划（2011-2030）（2017年修订）</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">二等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">4</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">庄河市仙人洞镇总体规划（2016-2030）</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">三等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">5</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">中心城区轨道交通沿线土地开发策略及重要站点周边城市形态设计</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">二等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">6</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">大连钻石海湾地区城市设计—全国城市设计试点重点项目</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">二等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">7</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">大连市域0.6米卫星形象采购及DOM制作</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">三等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">8</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">大连东关街历史街区周边城市设计</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">二等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">9</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">岔山村发展建设规划</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">三等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">10</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">大连市历史文化名城保护规划</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">一等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">11</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">大连市城镇体系规划（2018-2035年）</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">三等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">12</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">城乡规划信息化空间管控技术体系及应用系统建设</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">一等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">13</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽阳县前杜特色小镇建设规划</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">三等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">辽宁省优秀工程勘察设计奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2020</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="bottom" style="border:none;">
                <p class="MsoNormal" align="center" style="text-align:center;">
                  <span style="font-family:华文仿宋;font-size:18px;">&nbsp;</span> 
                </p>
              </td>
              <td width="372" valign="bottom" style="border:none;">
                <p class="MsoNormal" align="center" style="text-align:center;">
                  <span style="font-family:华文仿宋;font-size:18px;">&nbsp;</span> 
                </p>
              </td>
              <td width="148" valign="bottom" style="border:none;">
                <p class="MsoNormal" align="center" style="text-align:center;">
                  <span style="font-family:华文仿宋;font-size:18px;">&nbsp;</span> 
                </p>
              </td>
              <td width="235" valign="bottom" style="border:none;">
                <p class="MsoNormal" align="center" style="text-align:center;">
                  <span style="font-family:华文仿宋;font-size:18px;">&nbsp;</span> 
                </p>
              </td>
              <td width="147" valign="bottom" style="border:none;">
                <p class="MsoNormal" align="center" style="text-align:center;">
                  <span style="font-family:华文仿宋;font-size:18px;">&nbsp;</span> 
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>